<template>
  <div class="landing-container" style="align-items: center; padding-left: 3em;">
    <v-card class="pa-10">
      <v-card-title class="text-h2 title-break" style="padding-left: 0px;">
        We value your feedback.
      </v-card-title>
      <p class="text-body-1">
        Let us know how we are doing so that we can improve our service. Your feedback will<br/> remain anonymous.
      </p>
      <p class="mb-12 text-body-1">
        If you need help or have technical issues, please <router-link to="/contact">Contact Us</router-link>. 
      </p>
      <span class="font-weight-bold">
        How likely is it that you would recommend FastER to a colleague?
      </span>
      <v-slider
        max="10"
        min="1"
        step="1"
        ticks
        v-model="rating"
        tick-size="3"
        color="var(--eastman-blue)"
        track-color="var(--eastman-blue-transparent)"
        :tick-labels="ratingLabels"
        class="mb-8"
      />
      <v-textarea
        filled
        label="Please explain your rating."
        v-model="reason"
        class="mb-8"
      />
      <v-btn
        block
        dark
        large
        style="font-size: 16px;"
        color="var(--eastman-azure)"
        @click="onFeedbackSubmit"
        :loading="loading"
      >
        Submit Feedback
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import AccessAPI from '@/services/AccessServices.js'

export default {
  name: 'Feedback',
  data: function () {
    return {
      rating: 5,
      reason: '',
      ratingLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      loading: false,
    }
  },
  methods: {
    onFeedbackSubmit: async function () {
      var feedback = { Rating: this.rating, Reason: this.reason }
      this.loading = true;
      try {
        await AccessAPI.sendFeedback(feedback)
        this.loading = false;
        this.$router.push('/feedback/complete')
      } catch (error) {
        this.loading = false;
        console.error(error)
      }
    },
  },
}
</script>

<style>

</style>