import axios from "axios"

export default {
  sendAccessRequest: async function (accessRequest) {
    const url = `${process.env.VUE_APP_ACCESS_API_BASE}/Access`
    const response = await axios.post(url, accessRequest, { crossDomain: true })
    return response.data
  },
  sendFeedback: async function (feedback) {
    const url = `${process.env.VUE_APP_ACCESS_API_BASE}/Feedback`
    const response = await axios.post(url, feedback, { crossDomain: true })
    return response.data
  },
  getUserAccessRequest: async function () {
    const url = `${process.env.VUE_APP_ACCESS_API_BASE}/Access`
    const response = await axios.get(url, { crossDomain: true })
    return response.data
  },
}